html,
body {
  max-width: 100%;
  overflow-x: hidden;
  touch-action: pan-x pan-y;
  font-family: "Allerta", sans-serif;
  -webkit-tap-highlight-color: transparent;
}

.App {
  background-color: black;
}
