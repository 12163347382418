.mainContainer {
  display: flex;
  background-image: url("../../images/bg_underground.png");
  background-size: 70% 100%;
  background-position: left 50% top 0;
  background-repeat: no-repeat;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.responsiveContainer {
  width: unset !important;
  background-size: 100% 100%;
}

.contentContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  margin-top: 8%;
}

.contentContainerResponsive {
  width: 100%;
}

.columnWrapper {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
}

.title {
  width: 100%;
  margin-bottom: 6%;
}

.rowWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 55px;
  width: 100%;
}

.rowElement {
  display: flex;
  color: #bbbbbb;
}

.rowElementResponsive {
  padding: 0 10%;
  font-size: 84%;
}

.nftCollection {
  width: 43%;
}

.nftCollectionResponsive {
  width: 80%;
}

.textWrapper {
  color: #bbbbbb;
}

.textWrapperResponsive {
  padding: 0 10%;
  font-size: 84%;
}

.textWrapperLeft {
  color: #bbbbbb;
  text-align: start;
}

.textWrapperLeftResponsive {
  padding: 0 10%;
  font-size: 84%;
}

.traitsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.traitsWrapperResponsive {
  flex-direction: column;
}

.traitsWrapperResponsive img {
  width: 80% !important;
}

.traitsWrapper img {
  width: 50%;
}

.teamWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 4%;
}

.teamWrapperResponsive {
  justify-content: unset !important;
  padding: 0 10%;
  flex-direction: column;
  align-items: center;
}

.teamWrapper img {
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.columnResponsive {
  width: unset !important;
  font-size: 84%;
  padding-bottom: 5%;
}

.column p,
.column a {
  color: #bbbbbb;
}

.date {
  color: #bbbbbb;
  margin-top: 20%;
  margin-bottom: 20%;
  font-size: 30px;
}

.dateResponsive {
  font-size: 20px;
}

.deadCollection {
  width: 80%;
  margin-top: 4%;
}

.communityWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 5%;
}

.communityWrapper img {
  width: 70%;
  cursor: pointer;
}

.domain {
  text-align: bottom;
  display: flex;
  align-items: flex-end;
}

@media only screen and (max-width: 425px) {
  .responsiveContainer {
    background-image: url("../../images/bg_underground_responsive.png");
    background-size: 100% auto;
  }
}
