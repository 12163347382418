.tombstoneWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
}

.tombstone {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.tombstoneImage {
  position: relative;
  width: 46%;
  padding-top: 3%;
  padding-right: 2%;
}

.logo {
  position: absolute;
  top: 15px;
  z-index: 100;
}

.hole {
  position: relative;
}

.aboutLink,
.galleryLink,
.communityLink {
  position: absolute;
  width: 12%;
}

.aboutLink {
  top: 16%;
  left: 29%;
}

.galleryLink {
  bottom: 15%;
  left: 33%;
}

.teamLink {
  position: absolute;
  width: 9%;
  right: 34.6%;
  top: 55%;
}

.communityLink {
  bottom: 10%;
  right: 29.3%;
}

.galleryLinkMarker {
  width: 5%;
  height: 17%;
  transform: rotate(323deg);
  position: absolute;
  bottom: 19%;
  left: 36%;
  z-index: 5;
  cursor: pointer;
}

.aboutLinkMarker {
  width: 4%;
  height: 15%;
  transform: rotate(42deg);
  position: absolute;
  top: 15%;
  left: 34%;
  z-index: 5;
  cursor: pointer;
}

.teamLinkMarker {
  width: 5%;
  height: 12%;
  transform: rotate(35deg);
  position: absolute;
  right: 36%;
  top: 57%;
  z-index: 5;
  cursor: pointer;
}

.communityLinkMarker {
  width: 5%;
  height: 16%;
  transform: rotate(62deg);
  position: absolute;
  bottom: 10%;
  right: 32%;
  z-index: 5;
  cursor: pointer;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 70%;
  position: relative;
}

.wrapperResponsive {
  width: 100%;
}

.zombieHand {
  position: absolute;
  top: 0;
  left: 0;
}

.deadShowcase {
  position: absolute;
  width: 13.5%;
  bottom: 37.4%;
  left: 43.6%;
}

@media only screen and (max-width: 425px) {
  .wrapperResponsive {
    width: 200%;
  }
}
