.container {
  display: flex;
  opacity: 50%;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 15%;
  height: 35%;
  top: 50%;
  width: 3%;
  gap: 4%;
}

.section {
  height: 33%;
  border: 2px solid #1e2a1b;
  width: 28%;
  cursor: pointer;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.visited {
  background-color: rgb(95, 95, 95);
}
